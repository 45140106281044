import Section from "../global/Section";
import { FaTwitter, FaDiscord, FaMedium } from "react-icons/fa";
import Heading from "../global/Header";
export default function Cta() {
  return (
    <Section backgroundColor="primary-bg">
      <Heading>
        <span>Stay tuned!</span>
      </Heading>
      <Heading> Follow us on Twitter and join us on Discord.</Heading>
      <div className="hero-text">
        <div className="social-icons">
          <a href="https://twitter.com/TheVikingSol">
            <FaTwitter />
          </a>
          <a href="https://discord.gg/tZfvksxAfB">
            <FaDiscord />
          </a>
        </div>
      </div>
      <Heading>Read our article on medium</Heading>
      <div className="hero-text">
        <div className="social-icons">
          <a href="https://medium.com/@mrgs21.business?p=a863f49484ed">
            <FaMedium />
          </a>
        </div>
      </div>
    </Section>
  );
}
