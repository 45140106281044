import Heading from "../global/Header";
import Section from "../global/Section";
export default function Faq() {
  return (
    <Section backgroundColor="primary-bg">
      <Heading>FAQ</Heading>
      <div className="faq">
        <h3> Where can I mint The Vikings ? </h3>
        <p> We will announce the website on Discord and Twitter</p>
        <h3>How many Vikings are available for public mint?</h3>
        <p>
         Out of 10000 there are 9000 left to mint the rest was used in presale and promotions.

        </p>
        <h3>Which wallet can I use?</h3>
        <p>Phantom, Solflare, Slope, Sollet </p>
        <h3>
          What makes The Vikings different from the other NFT projects I have
          seen?
        </h3>
        <p>
          The Vikings is a NFT project to ever commit to giving 90% of the
          rewards generated from secondary market sales, straight back to the
          community. It is very simple, you own a Viking, you get paid every
          single week.
        </p>
      </div>
    </Section>
  );
}
