import "./App.css";
import Layout from "./components/Layout";
import Hero from "./components/landing/Hero";
import Roadmap from "./components/landing/Roadmap";
import Faq from "./components/landing/Faq";
import NotSame from "./components/landing/NotSame";
import Cta from "./components/landing/Cta";
function App() {
  return (
    <Layout>
      <Hero />
      <Roadmap />
      <NotSame />
      <Faq />
      <Cta />
    </Layout>
  );
}

export default App;
