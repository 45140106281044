import styles from "../../styles/Footer.module.css";
import logo from "../../images/vikinglogo.png";

export default function Footer() {
  return (
    <>
      <nav>
        <div className={styles["navbar-container"]}>
          <div className={styles["navbar"]}>
            <div className={styles["site-title"]}>
              <img src={logo} alt="" height="100px" width="auto" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
