import Section from "../global/Section";
import image from "../../images/viking2.jpeg";
export default function NotSame() {
  return (
    <Section backgroundColor="hero">
      <div className="ns">
        <div className="ns-image">
          <img alt="" src={image} />
        </div>
        <div className="ns-text">
          <h2> NO TWO VIKINGS ARE THE SAME. </h2>
          <p>
            All Vikings are warriors, but some are rarer than others. Each
            Viking is unique and programmatically generated from over 100+
            possible traits ranging from hairstyle, clothing, weapons and more.
          </p>
        </div>
      </div>
    </Section>
  );
}
