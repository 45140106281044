import styles from "../../styles/Navbar.module.css";
import logo from "../../images/vikinglogo.png";
export default function Navbar() {
  return (
    <>
      <nav>
        <div className={styles["navbar-container"]}>
          <div className={styles["navbar"]}>
            <div className={styles["site-title"]}>
              <img src={logo} alt="" height="40px" width="auto" />{" "}
              <span>THE VIKINGS</span>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
