import Heading from "../global/Header";
import Section from "../global/Section";
export default function Roadmap() {
  return (
    <Section backgroundColor="primary-bg">
      <Heading>Roadmap</Heading>
      <div class="timeline">
        <div class="container left">
          <div class="content">
            <h3>Presale Q4 2021</h3>
            <p>
              There will be 1 presale with 1000 participants for free Viking
              NFT's.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="content">
            <h3>Main sale</h3>
            <p>
              The remaining 9000 vikings are now. open for minting by anyone.
              (Total supply 10000)
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="content">
            <h3>Marketplace Launch</h3>
            <p>
              We will first launch into secondary-markets, so that holders have
              the ability to sell there vikings on a trust marketplace.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="content">
            <h3>Rarity </h3>
            <p>We will reveal the rarities of the Vikings.</p>
          </div>
        </div>
        <div class="container left">
          <div class="content">
            <h3>The VikingsDAO</h3>
            <p>
              Afterwards, all holders will be invited to a DAO discord to form a
              tight community and decide on the future of the project.
            </p>
          </div>
        </div>
        <div class="container right">
          <div class="content">
            <h3>Royalties Portal </h3>
            <p>
              We are going to launch a royalties system., our goal is that you
              claim your rewards once a week. This will allow you to earn solana
              while holding your viking. we really can't wait to put this in
              place The people who mint Vikings will forever be rewarded with
              10% of all royalties collected from secondary-market sales and
              holders get an additional 80% of all royalties
            </p>
          </div>
        </div>
        <div class="container left">
          <div class="content">
            <h3>Mini Game</h3>
            <p>
              You will be able to fight your Viking against another Viking. The
              winner will be able to win the other's NFT! To make it fair in
              terms of rarity, the rarest Viking will have more chance to win.
              Each Viking will have a rank according to his rarity (you will be
              able to see them 3-4 days after the drop), this rank will
              determine the percentage of chance for your Viking to win against
              the other.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}
