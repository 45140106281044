import Section from "../global/Section";
import heroImage from "../../images/viking1.jpg";
export default function Hero() {
  return (
    <Section backgroundColor="hero">
      <div
        style={{
          minHeight: "calc(100vh - 200px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img className="hero-image" src={heroImage} alt="" />
        </div>
        <div className="hero-text" style={{ marginTop: "90px" }}>
          <h2> WELCOME TO THE VIKINGS FAMILY !</h2>

          <p>
            The vikings army is a high-quality collection of 10000 Viking
            NFTs—unique digital collectibles swimming in the Solana blockchain.
            Once a Viking is possessed, you automatically become a member of the
            family.
            <br />
            The goal is not to do things quickly but rather to create a cohesive
            and active community.
          </p>
        </div>
        <br />
        <div className="hero-text">
          <h2>A SELECTING AND TRUSTING TEAM ONLY FOR YOU !</h2>

          <p>
            The Viking team is not a group of strangers working together. Each
            member of this group was selected for his qualities and talent,
            everyone know each other and trust is the foundation of the Vikings
            !
          </p>
        </div>
      </div>
    </Section>
  );
}
